<template>
    <div class="info-banner-outer attention">
        <div class="info-banner">
            <div class="icon__block"><span class="fm-icon-info"></span></div>
            <div class="info-banner-text">
                <p>
                    Ваш ключ API не работает и данные по нему не подгружаются.<br>
                    Внесите, пожалуйста, новый ключ из личного кабинета поставщика маркетплейса. <a :href="getApiKeyLink">Здесь</a>
                    вы можете прочитать инструкцию, как сгенерировать новый ключ. Новый ключ вы можете внести
                    самостоятельно в разделе Настройки или кликнув по этой <a :href="changeApiKeyLink">ссылке</a>.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/store'

export default {
    name: 'BrokenApiKey',
    data() {
        return {
            store
        }
    },
    computed: {
        getApiKeyLink() {
            if (!this.store.account.data_source) {
                return '#'
            }

            return (this.store.account.data_source === 'wildberries' ? '/wb-key-expired' : '/ozon-key-expired') + '?account=' + btoa(this.store.currentAccount);
        },
        changeApiKeyLink() {
            return '/settings#changeApiKey'
        }
    },
}
</script>
