<template>
    <tr>
        <td class="nowrap" style="text-align: left;">
            {{ mail.email }}
        </td>
        <td class="nowrap" style="text-align: left;">
            {{ mail.subject }}
        </td>
        <td>
            {{ mail.created_at }}
        </td>
        <td class="nowrap" style="text-align: left;">
            <button @click="modal" class="index_filter_button active">Открыть</button>
        </td>
    </tr>

</template>

<script>
import moment from "moment";

export default {
    props: {
        mail: {
            type: Object,
            required: true
        }
    },

    emits: ['open'],

    mounted() {
        this.mail.created_at = moment(this.mail.created_at).format('DD.MM.YYYY HH:mm')
    },

    methods: {
        modal() {
            this.$emit('open');
        }
    }
}
</script>
<script setup lang="ts">
</script>
