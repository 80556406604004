<template>
    <li class="notification" v-click-outside="hideNotification">
        <button class="notification__trigger" type="button" @click="toggleNotification">
            <i class="fm-bell"></i>
            <span v-show="newNotificationsCount" class="notification__counter">{{ newNotificationsCount }}</span>
        </button>

        <div class="notification-list" v-show="show && notifications.length">
            <div class="notification-list__inner">
                <div class="notification-list__items" v-for="(notificationsList, date) in newNotificationsGrouped">
                    <div class="notification-list__itemsGroupDate">{{ date }}</div>

                    <template v-for="notification in notificationsList">
                        <div v-if="notification.block_style === null" class="notification-item"
                             :class="{'notification-item_new': !notification.seen}">
                            <div class="notification-item__ico"><i class="fm-alert-triangle"></i></div>
                            <div class="notification-item__body">
                                <div class="notification-item__title">{{ notification.title }}</div>
                                <div class="notification-item__content">{{ notification.message }}</div>
                            </div>
                            <div>
                                <div class="notification-item__datetime">{{ notification.created_at_hours }}</div>
                                <a v-if="notification.link" class="notification-item__link" :href="notification.link"><i
                                    class="fm-arrow-right"></i></a>
                            </div>
                        </div>

                        <div v-if="notification.block_style === 'alert'" class="notification-item _shop _noHover">
                            <img class="notification-item__image"
                                 :src="`/img/${accountInfo.data_source === 'wildberries' ? 'wb' : 'ozon'}-ico.svg`"
                                 width="40" height="40" alt="" loading="lazy">
                            <div class="notification-item__body">
                                <div class="notification-item__title">{{ notification.title }}</div>
                                <div class="notification-item__content">{{ notification.message }}</div>
                            </div>
                            <div>
                                <div class="notification-item__datetime">{{ notification.created_at_hours }}</div>
                                <div class="notification-item__ico"><i class="fm-alert-triangle"></i></div>
                            </div>
                        </div>

                        <div v-if="notification.block_style === 'success'"
                             class="notification-item _shop _noHover _success">
                            <img class="notification-item__image"
                                 :src="`/img/${accountInfo.data_source === 'wildberries' ? 'wb' : 'ozon'}-ico.svg`"
                                 width="40" height="40" alt="" loading="lazy">
                            <div class="notification-item__body">
                                <div class="notification-item__title">{{ notification.title }}</div>
                                <div class="notification-item__content">{{ notification.message }}</div>
                            </div>
                            <div>
                                <div class="notification-item__datetime">{{ notification.created_at_hours }}</div>
                                <div class="notification-item__ico"><i class="fm-check"></i></div>
                            </div>
                        </div>
                    </template>
                    <!--
                    <div class="notification-item _product _noHover _alert">
                        <img class="notification-item__image" src="https://picsum.photos/80" width="40" height="40" alt="" loading="lazy">
                        <div class="notification-item__body">
                            <div class="notification-item__title">Мини-кондиционер воздуха</div>
                            <div class="notification-item__content">26880476 / 98632083817847139255<br>к&nbsp;заказу через 5&nbsp;дней</div>
                        </div>
                        <div>
                            <div class="notification-item__datetime">14:00</div>
                            <div class="notification-item__ico"><i class="fm-alert-triangle"></i></div>
                        </div>
                    </div>
                    -->
                </div>

                <div v-if="displayShowMore" class="notification-list__footer">
                    <button class="btn-default btn-regular" type="button">Показать больше</button>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import axios from "axios";

export default {
    props: ['notifications', 'accountInfo'],

    data() {
        return {
            show: false,
            displayShowMore: false
        }
    },

    computed: {
        newNotificationsCount: function () {
            return this.newNotifications.length;
        },
        newNotifications: function () {
            return this.notifications.filter(item => !item.seen);
        },
        newNotificationsGrouped: function () {
            return this.notifications.reduce((groups, item) => {
                const date = new Date(item.created_at).toLocaleDateString(); // Group by date
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(item);
                return groups;
            }, {});
        }
    },

    watch: {
        show(newShow, oldShow) {
            const markAsSeenIds = this.newNotifications.map(notification => notification.id);
            if (markAsSeenIds.length && !newShow && oldShow) {
                axios.post('/notifications/mark-as-read', {'ids': markAsSeenIds});
            }
        }
    },

    methods: {
        toggleNotification: function () {
            this.show = !this.show;
        },

        hideNotification: function () {
            this.show = false;
        },
    }
}
</script>
