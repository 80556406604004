<template>

    <div class="main-wrapper">
        <div class="dash__board__wrapper">
            <aside class="aside">
                <div class="logo_block"><a :href="route('admin.partners.index')"><img alt="logo"
                                                                                      src="/img/logo.svg"></a></div>
                <div class="main_nav">
                    <div v-if="isSuperAdmin" class="main_nav_item">
                        <Link :class="{'active':route().current('admin.partners.index')}"
                              :href="route('admin.partners.index')"
                              class="main_link">
                            <div><span class="fm-layout"></span><span class="title">Партнеры</span></div>
                        </Link>
                    </div>
                    <div v-if="isSuperAdmin" class="main_nav_item">
                        <Link :class="{'active':route().current('admin.users.index')}"
                              :href="route('admin.users.index')"
                              class="main_link">
                            <div><span class="fm-layout"></span><span class="title">Пользователи</span></div>
                        </Link>
                    </div>
                    <div v-if="isSuperAdmin" class="main_nav_item">
                        <Link :class="{'active':route().current('admin.files.index')}"
                              :href="route('admin.files.index')"
                              class="main_link">
                            <div><span class="fm-file"></span><span class="title">Файлы</span></div>
                        </Link>
                    </div>
                    <div v-if="isSuperAdmin" class="main_nav_item">
                        <Link :class="{'active':route().current('admin.mail.index')}"
                              :href="route('admin.mail.index')"
                              class="main_link">
                            <div><span class="fm-layout"></span><span class="title">Mails</span></div>
                        </Link>
                    </div>
                    <div class="main_nav_item">
                        <Link :class="{'active':route().current('admin.transaction.index')}"
                              :href="route('admin.transaction.index')"
                              class="main_link">
                            <div><span class="fm-layout"></span><span class="title">Отчеты по оплатам</span></div>
                        </Link>
                    </div>
                </div>
            </aside>
            <div class="auth_row">
                <div class="auth_row_inner">
                    <div class="auth_title_block">
                        <button aria-label="Меню"
                                class="tf_mobile__switcher js_trigger__click"
                                data-element=".aside, .dash__board__container, .auth_row"
                                data-toggle="false" type="button">

                            <svg class="feather feather-menu" fill="none" height="24" stroke="currentColor"
                                 stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                 width="24" xmlns="http://www.w3.org/2000/svg">
                                <line x1="3" x2="21" y1="12" y2="12"></line>
                                <line x1="3" x2="21" y1="6" y2="6"></line>
                                <line x1="3" x2="21" y1="18" y2="18"></line>
                            </svg>
                        </button>
                        <div class="page_title_outer">
                            <h1 class="page_title">{{ __("admin.hint." + route().current() + ".title") }}</h1>

                        </div>
                    </div>
                    <ul class="auth_row_inner_nav">
                        <li class="user_auth_dropdown">
                            <BreezeDropdown>
                                <template #trigger>
                                    <div class="user_block">
                                        <div class="user_thumb">
                                            <svg class="feather feather-user" fill="none" height="24"
                                                 stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                 stroke-width="2"
                                                 viewBox="0 0 24 24" width="24"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg>
                                        </div>
                                        <div class="user_account_status">&nbsp;</div>
                                    </div>
                                </template>

                                <template #content>
                                    <div class="user_auth_nav_item logout">
                                        <BreezeDropdownLink :href="route('admin.logout')"
                                                            class="user_auth_nav_item_link"
                                                            method="post">
                                            <span>Выйти</span>
                                        </BreezeDropdownLink>
                                    </div>
                                </template>
                            </BreezeDropdown>
                        </li>
                    </ul>
                </div>
            </div>
            <slot/>
        </div>
    </div>
    <footer class="data_loader_open">
        <div class="feedback_block">
            <p>По любым вопросам пишите нам на:<br>
                <a href="mailto:support@sellerdata.ru">support@sellerdata.ru</a></p>
        </div>
    </footer>

    <div id="modalLogoutConfirm" aria-hidden="true" aria-labelledby="modal_notificationDelete_label"
         class="modal modal-fullscreen logout-confirm" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button aria-label="Back" class="modal-back" data-dismiss="modal" type="button">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <h5 class="h4 modal-title">Подтверждение</h5>
                    <button aria-label="Close" class="modal-close" data-dismiss="modal" type="button">
                        <span class="fm-close"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <h6 class="logout-confirm__title text-center">Вы уверены что хотите выйти?</h6>
                </div>
                <div class="modal-footer">
                    <button aria-label="Close" class="btn-default" data-dismiss="modal">Отменить</button>
                    <button aria-label="Close" class="btn-default btn-regular" @click="logoutConfirm">Да</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreezeDropdown from '@/Components/Dropdown.vue'
import BreezeDropdownLink from '@/Components/DropdownLink.vue'
import {Link} from '@inertiajs/inertia-vue3'
import Toast from '@/Components/Notification/Toast.vue'
import {Inertia} from '@inertiajs/inertia'
import Modal from '@/Components/Notification/Modal'

export default {
    components: {
        BreezeDropdown,
        BreezeDropdownLink,
        Link,
        Toast,
        Modal,
    },

    data() {
        return {}
    },

    computed: {
        isSuperAdmin() {
            return this.$parent.$attrs.user.super_admin;
        }
    },

    methods: {
        logout: function () {
            $('#modalLogoutConfirm').modal();
            return false;
        },
        logoutConfirm: function () {
            $('#modalLogoutConfirm').modal('hide');

            if (typeof window.accountInfoInterval !== 'undefined') {
                clearInterval(window.accountInfoInterval);
                delete window.accountInfoInterval;
            }

            Inertia.visit(this.route('logout'), {
                method: 'post'
            });
        },
        jsActiveTrigger: function () {
            let opened = false,
                dataToggle = '',
                $element = '',
                $this = '';
            let clickHandler = 'click';
            if ('ontouchstart' in document.documentElement) {
                clickHandler = 'touchend';
            }
            $(document).on(clickHandler, '.js_trigger__click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $this = $(this);
                if ($this.hasClass('tf_mobile__switcher')) {
                    $('body , html').toggleClass('fixed__body');
                }
                dataToggle = $(this).attr('data-toggle');
                if (typeof dataToggle === 'undefined') dataToggle = true;
                let element = $(this).attr('data-element');
                if (typeof element !== 'undefined') $element = $(element);

                if (dataToggle && dataToggle !== 'false') {
                    $element.slideToggle('slow');
                } else {
                    $element.toggleClass('active');
                }
                $(this).toggleClass('active');
                opened = !!$(this).hasClass('active');
            });
            $(document).on(clickHandler, function (e) {
                let notifyBar = $('.user_auth_dropdown');
                if ($(e.target).is(notifyBar) === false && notifyBar.has(e.target).length === 0) {
                    $(".user_auth_dropdown").removeClass("active");
                    $('.user_block').removeClass("active");
                }
            });
            $(document).on(clickHandler, '.hideText, .close-loader', null, function () {
                $(this).parent().fadeOut();
            });
            $(document).on(clickHandler, '.icon-delete, .close_info', null, function () {
                $(this).parent().parent().fadeOut();
            });
            $(document).on(clickHandler, '.hide_banner', null, function () {
                $(this).parent().parent().parent().fadeOut();
            });
            $(document).on('click', '.expenses-table tbody tr:not(.total_table_row) td:not(.action-td)', null, function () {
                $('#modal_change').modal('show');
            });
            $(document).on(clickHandler, '#mytable button.move', null, function () {
                let row = $(this).closest('table').find('tbody tr');
                if (row.hasClass('up'))
                    row.prev().before(row);
                else
                    row.next().after(row);
            });
        },
        setMenuDefaultState: function () {
            $('body , html').removeClass('fixed__body');
            $('.tf_mobile__switcher').removeClass('active');
        },
    },

    mounted() {
        this.setMenuDefaultState();
        if (typeof window.isComponentsMounted == 'undefined') {
            this.jsActiveTrigger();
            window.isComponentsMounted = true;
        }
    }
}
</script>
