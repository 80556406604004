<template>
    <tr class="products-grouped">
        <td data-title="Наименование" @click="openChildren()">
            <div class="item">
                <button aria-label="Раскрыть/скрыть" :aria-selected="this.accordion_opened"
                        class="table-accordion__trigger"
                        type="button"></button>
                <div class="inner-relative"><span>{{ user.email }}</span></div>
            </div>
        </td>
    </tr>

    <tr :class="{hidden: !accordion_opened}" class="table-accordion__content">
        <td>
            <table class="table table-fixed table-fw-widget partners-table table-condensed dashboard-table">
                <thead>
                <tr>
                    <th class="nowrap" style="text-align: left;">
                        Название
                    </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="file in user.files">
                    <tr class="cursor-pointer" @click="download(file)">
                        <td>
                            <div class="item">
                                <div class="inner-relative"><span>{{ file }}</span></div>
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </td>
    </tr>

</template>
<script>
export default {
    data() {
        return {
            user: this.user,
            accordion_opened: false,
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        },
    },

    methods: {
        generateLink(user) {
            axios.post(route('admin.users.login.generate'), {
                user_id: user.id
            }).then(response => {
                window.open(response.data, "_blank");
                // this.link = response.data;
            }).catch(error => {
                console.log(error);
            });
        },

        download(file) {
            window.open(route('admin.files.download', {'user_id': this.user.id, 'file': file}), "_blank");

            // axios.post(route('admin.files.download'), {
            //     user_id: this.user.id,
            //     file: file,
            // }).then(response => {
            // }).catch(error => {
            //     console.log(error);
            // });
        },

        openChildren() {
            this.accordion_opened = !this.accordion_opened;
        }
    }
}
</script>
