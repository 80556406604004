<template>
    <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="productsFilterModal" tabindex="-1"
         aria-labelledby="productsFilterModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <button v-if="(filterSection === 'products')" class="modal-back modal-back_show" type="button"
                            aria-label="Back" @click="changeFilterSection('')">
                        <i class="fm-arrow-left"></i>
                    </button>
                    <button v-else class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                        <i class="fm-arrow-left"></i>
                    </button>

                    <h5 v-if="filterSection === 'products'" class="modal-title" id="productsFilterModalTitle"
                        @click="changeFilterSection('')">Товары</h5>
                    <h5 v-else class="modal-title" id="productsFilterModalTitle">Фильтр</h5>

                    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                        <i class="fm-close"></i>
                    </button>
                </div>
                <div class="modal-body scrollbar">
                    <div v-show="filterSection === ''" class="filter-list">
                        <button class="filter-list__item" type="button" @click="changeFilterSection('products')">
                            <i class="filter-list__ico fm-clipboard"></i>
                            <span v-if="productsSelectedLocal.length"
                                  class="filter-list__title">Выбрано: {{ productsSelectedLocal.length }} товара</span>
                            <span v-else class="filter-list__title">Товары</span>
                            <i class="filter-list__ico fm-chevron-right"></i>
                        </button>

                        <div class="info-list__input info-list__input_last" v-if="hiddenCount > 0 || query.hidden == 1">
                            <div class="info-list__input__inner">
                                <input class="input__checkbox" @change="changeHidden()" :checked="query.hidden == 1"
                                       id="show-hidden-products" type="checkbox">
                                <label class="input__checkbox-label" for="show-hidden-products">Показывать скрытые
                                    <span>({{ hiddenCount }})</span></label>
                            </div>
                        </div>
                        <div class="info-list__input info-list__input_last">
                            <div class="info-list__input__inner">
                                <input class="input__checkbox" @change="changeCostEmpty()"
                                       :checked="query.costempty === 1" id="show-emptycost-products" type="checkbox">
                                <label class="input__checkbox-label" for="show-emptycost-products">Нулевая
                                    себестоймость</label>
                            </div>
                        </div>
                    </div>

                    <div class="filter-products" v-show="filterSection === 'products'">
                        <ProductsSelector
                            ref="ProductsSelector"
                            v-bind:products="productsSelected"
                            v-bind:isInline="true"
                            @changed="selectedProductsChanged"></ProductsSelector>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" @click="$emit('clear')" aria-label="Close" class="btn-default" v-show="filterSection === ''">
                        Сбросить
                    </button>
                    <button v-if="(filterSection === 'products')" class="btn-default btn-regular"
                            @click="changeFilterSection('')">Сохранить
                    </button>
                    <button v-else @click="apply" data-dismiss="modal" aria-label="Close"
                            class="btn-default btn-regular">Фильтровать
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductsSelector from "@/Components/Products/ProductsSelector";

export default {
    components: {
        ProductsSelector
    },
    props: [
        'query',
        'productsSelected',
        'hiddenCount',
    ],
    emits: [
        'apply',
        'showHidden',
        'showCostEmpty',
        'clear'
    ],
    data() {
        return {
            filterSection: '',
            productsSelectedLocal: [],
        };
    },
    methods: {
        apply() {
            this.$emit('apply');
        },
        selectedProductsChanged(data) {
            this.productsSelectedLocal = data.productIds;
            this.$emit('selectedProductsChanged', data);
        },
        changeHidden() {
            this.$emit('showHidden');
        },
        changeCostEmpty() {
            this.$emit('showCostEmpty');
        },
        changeFilterSection(filterSection = '') {
            this.filterSection = filterSection;
            if (filterSection === 'products') {
                this.$refs.ProductsSelector.openMultiselect(true);
                this.$refs.ProductsSelector.mobileEventAdd('productsFilterModal');
            } else {
                this.$refs.ProductsSelector.destroyMobileEventAdd();
            }

        },
        jsInit() {
            let self = this;
            $(document).off('show.bs.modal', '#productsFilterModal');
            $(document).on('show.bs.modal', '#productsFilterModal', function (e) {
                self.filterSection = '';
                // self.$refs.ProductsSelector.openMultiselect(true);
            })
        }
    },
    mounted() {
        this.productsSelectedLocal = this.productsSelected;
        this.jsInit();
    }
}
</script>
