<template>
    <div class="settings__accounts__item">
        <div class="settings__accounts__item__header">
            <div class="popover_block" :class="{active: popovers.name}">
                <a @click="popoverOpen('name')" class="popover_block_link open_popover settings__accounts__item__header__serviceName"><span>{{ userAccount.title ?? 'Основной магазин' }}</span></a>
                <div class="popover_block_item">
                    <div class="popover_block_item_inner">
                        <div class="popover_title">
                            <BreezeInput id="nameInput" type=""
                                         :class="{form_error: form.errors.name}"
                                         class="popover_input" v-model="form.name"
                                         @keyup.enter="saveUser('name')"
                                         placeholder="Название аккаунта"
                                         autocomplete="off"/>
                            <div v-if="form.errors.name" class="popover_info error show">
                                {{ form.errors.name }}
                            </div>
                        </div>
                        <div class="controls_buttons">
                            <div class="popover_status" @click="saveUser('name')"><span
                                class="fm-check"></span>
                            </div>
                            <div class="close_popover" @click="popoverClose('name')">
                                <span class="fm-x"></span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.userAccount.account.data_source === 'wildberries' || this.userAccount.account.data_source === 'wildberries_demo'" class="settings__accounts__item__header__service">Wildberries</div>
            <div v-else class="settings__accounts__item__header__service">Ozon</div>
        </div>

        <div class="settings__accounts__list">
            <!--
            <AccountApiKey
                :account="{id: userAccount.id, ...userAccount.account}"
                @notification="($event) => $emit('notification', $event)"
                @reload="($event) => $emit('reload')"
            />
            -->
            <template v-if="userAccountHasInfo && this.userAccount.account.status && this.userAccount.account.info?.features && this.userAccount.account.data_source !== 'ozon'">
                <div v-if="this.userAccount.account.info.features.includes('statistics')"
                     class="popover_link_button success">
                    <span class="popover_link_button__ico fm-check"></span>
                    <span class="button-title">Статистика</span>
                </div>
                <div v-else class="popover_link_button _disabled">
                    <span class="button-title">Статистика</span>
                </div>

                <div v-if="this.userAccount.account.info.features?.includes('advert')"
                     class="popover_link_button success">
                    <span class="popover_link_button__ico fm-check"></span>
                    <span class="button-title">Реклама</span>
                </div>
                <div v-else class="popover_link_button _disabled">
                    <span class="button-title">Реклама</span>
                </div>

                <div v-if="this.userAccount.account.info.features?.includes('contentanalytics')"
                     class="popover_link_button success">
                    <span class="popover_link_button__ico fm-check"></span>
                    <span class="button-title">Аналитика</span>
                </div>
                <div v-else class="popover_link_button _disabled">
                    <span class="button-title">Аналитика</span>
                </div>
            </template>
            <template v-if="!this.userAccount.account.status">
                <div class="popover_link_button warning cursor-pointer open_modal_apikey" @click="forceOpen = !forceOpen">
                    <span class="popover_link_button__ico fm-alert-circle"></span>
                    <span class="button-title">Срок действия ключа истёк</span>
                </div>
            </template>
        </div>

        <div class="settings__accounts__actions">
            <UpdateAccountApiKeyLink
                :account="{id: userAccount.id, ...userAccount.account}"
                :forceOpen="forceOpen"
                @notification="($event) => $emit('notification', $event)"
                @reload="($event) => $emit('reload')"
                @forceClose="() => forceOpen = false"
            />
            <DeleteAccountLink
                v-if="this.allowdelete"
                :account-id="userAccount.id"
                @notification="($event) => $emit('notification', $event)"
                @reload="($event) => $emit('reload')"
            />
        </div>
    </div>
</template>

<script>
import BreezeInput from '@/Components/Input.vue'
import DeleteAccountLink from '@/Components/Settings/DeleteAccountLink.vue'
import UpdateAccountApiKeyLink from '@/Components/Settings/UpdateAccountApiKeyLink.vue'
import AccountApiKey from '@/Components/Settings/AccountApiKey.vue'

export default {
    components: { AccountApiKey, DeleteAccountLink, UpdateAccountApiKeyLink, BreezeInput},
    props:['userAccount','allowdelete'],
    emits: ['notification','reload'],
    data() {
        return {
            account: { id: this.userAccount.id, ...this.userAccount.account },
            form: this.$inertia.form({
                name: '',
                account_id: this.userAccount.id,
                // 'updates_subscription': false
            }),
            popovers: {
                name: false,
            },
            forceOpen:false,
            loading:false,
        }
    },
    computed: {
        userAccountHasInfo() {
            return Boolean(this.userAccount?.account?.info);
        }
    },
    mounted() {
        this.startPopUp();
    },
    methods:{
        startPopUp(){
            this.form.name = this.userAccount.title ?? 'Основной Магазин';
            this.form.account_id = this.userAccount.id;
        },
        popoverOpen: function (attribute) {
            this.popovers[attribute] = true;
        },
        popoverClose: function (attribute) {
            this.popovers[attribute] = false;
        },
        saveUser: function (attribute) {
            let self = this;

            this.form.post(this.route('settings.changeUserName'), {
                preserveScroll: true,
                onSuccess: function(response){
                    self.popovers[attribute] = false;
                    self.$emit('reload');
                },
                onError: function (errors) {
                    //if (errors.modal)
                    //    $('#errorKey').modal();
                },
            });
        },
    },
}
</script>
