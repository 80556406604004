<template>
    <Head title="Mails - Admin"/>

    <BreezeAdminLayout>
        <template #header-title>
            <h1 class="page_title">Mail</h1>
        </template>

        <div class="dash__board__container">
            <div class="main_container">

                <div class="filter-panel">
                    <div class="filter-panel__inner">
                        <div class="input input_combo input_combo_2">
                            <div class="input__inner">
                                <input id="selectSearchProductRedeems"
                                       v-model="filterQuery.search"
                                       class="input__text-input"
                                       placeholder="Поиск" type="text"
                                       v-on:keyup.enter="searchStart()">
                                <div class="input__actions">
                                    <button v-if="filterQuery.search" aria-label="Сбросить" class="input__btn fm-x"
                                            type="button"
                                            @click="clearFilterSearch()"></button>
                                    <button aria-label="Искать" class="input__btn fm-search" type="button"
                                            @click="searchStart()"></button>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-center">
                            <select v-model="filterQuery.theme">
                                <option value="">Выберите тему</option>
                                <option v-for="item in themes"
                                        :selected="item === filterQuery.theme"
                                        :value="item">
                                    {{ item }}
                                </option>
                            </select>
                        </div>

                        <button class="btn-default ml-4 btn-regular" type="button"
                                @click="searchStart()">
                            Применить
                        </button>

                        <button class="btn-default ml-4 btn-regular" type="button"
                                @click="clearFilter()">
                            Офистить фильтр
                        </button>
                    </div>
                </div>

                <div class="table_outer">
                    <div class="expenses-wrapper">
                        <table v-if="mails"
                               class="table table-fixed table-fw-widget partners-table table-condensed dashboard-table admin-users-table">
                            <thead>
                            <tr>
                                <th class="nowrap" style="text-align: left;">
                                    Адрес
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Тема
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Дата отправки
                                </th>
                                <th class="nowrap" style="text-align: left;">
                                    Действия
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(mail, key) in mails" :key="key">
                                <Row :mail="mail" @open="openModal(mail.body)"/>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-last-section" v-if="links.length > 3">
                                    <span v-for="link in links">
                                        <a v-if="link.url || link.label === '...'"
                                           :class="link.active ? 'text-gray-700' : ''"
                                           :href="link.url"
                                           class="mx-2" @click="selectPage(link.url)" v-html="link.label">
                                        </a>
                                    </span>
                </div>
            </div>
        </div>

        <div aria-hidden="true"
             class="modal modal-fullscreen" :style="showModal ? 'display:block' : ''" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down"
                 style="max-width: 50%">
                <div class="modal-content">
                    <div class="modal-header">
                        <button aria-label="Back" class="modal-back" data-dismiss="modal" type="button">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <button aria-label="Close" class="modal-close" data-dismiss="modal" type="button"
                                @click="close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h6 class="logout-confirm__title text-center" v-html="mailBody">
                        </h6>
                    </div>
                </div>
            </div>
        </div>

    </BreezeAdminLayout>

</template>

<script>
import BreezeAdminLayout from '@/Layouts/Admin.vue'
import BreezeButton from '@/Components/Button.vue'
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';
import Currency from "@/Components/Common/Currency.vue";
import ProductsSelector from "@/Components/Products/ProductsSelector.vue";
import UserRow from "@/Pages/Admin/Users/Components/UserRow.vue";
import FilterDateRanges from "@/Pages/Admin/Common/FilterDateRanges.vue";
import Input from "@/Components/Input.vue";
import Pagination from "@/Components/Common/Pagination.vue";
import Select2 from 'vue3-select2-component'
import Checkbox from "@/Components/Checkbox.vue";
import Row from "@/Pages/Admin/Mails/Components/Row.vue";
import {pickBy} from "lodash-es";

export default {

    components: {
        Row,
        Checkbox,
        Pagination,
        Input,
        FilterDateRanges,
        UserRow,
        ProductsSelector,
        Currency,
        BreezeAdminLayout,
        BreezeButton,
        BreezeCheckbox,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link,
        Select2
    },

    props: [
        'mails',
        'links',
        'total',
        'search',
        'selectedTheme',
        'themes'
    ],

    data() {
        let filter = {
            search: '',
            theme: '',
            dateFrom: null,
            dateTo: null,
        };

        return {
            showModal: false,
            mailBody: '',
            filterQuery: filter,
        }
    },

    mounted() {
        this.filterQuery.search = this.search;
        this.filterQuery.theme = this.selectedTheme;
    },

    methods: {
        selectPage(url) {
            if (url === '...') {
                return;
            }
            this.$inertia.get(url);
        },

        close() {
            this.showModal = false;
        },

        openModal(mailBody) {
            this.showModal = true;
            this.mailBody = mailBody;
        },

        searchStart() {
            this.$inertia.get(route('admin.mail.index'), pickBy(this.filterQuery));
        },

        clearFilterSearch() {
            this.filterQuery.search = '';
            this.searchStart();
        },

        clearFilter() {
            this.filterQuery = {
                search: '',
                dateFrom: null,
                dateTo: null,
                subType: '',
                hasInvoice: false,
            };
            this.$inertia.get(route('admin.mail.index'));
        },
    }
}
</script>
