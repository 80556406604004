<template>
    <Head title="Log in (Admin)"/>

    <div class="auth__wrapper">
        <div class="form__outer">
            <div class="auth__block">
                <div class="auth-block-inner">
                    <div class="form-title text-center">
                        <h6 class="size-16 ls-0_03em">Авторизация (Админ)</h6>
                    </div>
                    <div v-if="status" class="info_block success_block">
                        <div class="info_block_icon"><span class="fm-check"></span></div>
                        <div class="info_block_content">
                            <div class="block_content_text">
                                {{ status }}
                            </div>
                        </div>
                    </div>
                    <form id="loginForm" @submit.prevent="submit" class="ajax-auth">
                        <div class="form__group email__input input_block">
                            <label for="loginEmail" style="display:none;"></label>
                            <BreezeInput id="loginEmail" type="text" class='form-control'
                                         :class="{ form_error: form.errors.login }"
                                         v-model="form.login"
                                         placeholder="Login"
                                         @keyup="clearError"
                                         autofocus
                                         autocomplete="username"/>
                            <p v-if="form.errors.login" class="invalid-feedback">{{
                                    form.errors.login[0]
                                }}</p>
                        </div>
                        <div class="form__group password__input input_block">
                            <label for="loginPassword" style="display:none;"></label>
                            <BreezeInput id="loginPassword" type="password" class="form-control"
                                         v-model="form.password"
                                         placeholder="Пароль" autocomplete="current-password"/>
                        </div>
                        <div class="login_button_outer">
                            <BreezeButton class="submit_button" :class="{ 'opacity-25': form.processing }"
                                          :disabled="form.processing">
                                Войти
                                <span class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </BreezeButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, minLength} from '@vuelidate/validators'
import BreezeButton from '@/Components/Button.vue'
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeGuestLayout from '@/Layouts/Guest.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';

export default {
    setup: () => ({v$: useVuelidate()}),
    layout: BreezeGuestLayout,

    components: {
        BreezeButton,
        BreezeCheckbox,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link,
    },

    props: {
        canResetPassword: Boolean,
        status: Object,
    },
    computed: {
        errors() {
            return this.$page.props.errors
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                login: '',
                password: '',
                remember: false,
                recaptcha_token: null,
                statusData: [],
            })
        }
    },
    validations() {
        return {
            form: {
                login: {
                    required: helpers.withMessage('Поле обязательно для заполнения', required),
                },
                password: {
                    required: helpers.withMessage('Поле обязательно для заполнения', required),
                },
            }
        }
    },

    methods: {
        clearError() {
            this.form.errors = [];
        },
        async submit() {
            this.form.post(this.route('admin.login'), {
                onFinish(response) {
                    if (response.status === 200) {
                        window.location.href = '/admin/users';
                    }
                },
                onError(response) {
                    console.log('error');
                }
            })
        }
    }
}
</script>
