<template>
    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>1</span>
                <p>Перейдите в личный кабинет поставщика в WildBerries по <a href="https://seller.wildberries.ru/supplier-settings/access-to-api" target="_blank">этой ссылке</a> (если Вы не авторизованы, авторизуйтесь).</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/connect-1.jpg" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>2</span>
                <p>Перейдите на страницу настроек</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/connect-2.jpg" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>3</span>
                <p>Перейдите на вкладку “Доступ к новому API”</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/Screenshot_1.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>4</span>
                <p>Согласитесь с Офертой о реализации товара на Wildberries или пропустите этот пункт, если делали это ранее</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/connect-4.jpg" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>5</span>
                <p>Нажмите на кнопку “Создать новый ключ”. Удаляйте ранее созданный ключ, только если вы уверены, что он нигде больше не используется.</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/Screenshot_5.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>6</span>
                <p>Введите имя токена на&nbsp;ваше усмотрение. Выберите категории: &laquo;Статистика&raquo;, &laquo;Аналитика&raquo; и&nbsp;&laquo;Продвижение&raquo; и&nbsp;поставьте галочку &laquo;Только на&nbsp;чтение&raquo;. Нажмите кнопку &laquo;Создать токен&raquo;.</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/API-key-creation.png" width="567" height="562" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>7</span>
                <p>Скопируйте созданный ключ и внесите его в поле ниже на этой странице, чтобы подключить API Wildberries к Sellerdata.</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/Screenshot_4.png" width="567" height="514" alt="image">
        </div>
    </div>

    <template v-if="isKeyExpired">
        <div class="response_block">
            <div class="response_block_inner">
                <article class="response_content">
                    <span>8</span>
                    <p>В&nbsp;разделе Настройки&nbsp;&mdash; Общие выберите магазин, для которого необходимо заменить ключ и&nbsp;нажмите кнопку &laquo;Обновить ключ&raquo;</p>
                </article>
            </div>
            <div class="response_thumb">
                <img class="response_image" src="/img/connect/wb/change-key.png" width="567" height="400" alt="">
            </div>
        </div>

        <div class="response_block">
            <div class="response_block_inner">
                <article class="response_content">
                    <span>9</span>
                    <p>Внесите новый ключ в появившемся окне и сохраните изменения</p>
                </article>
            </div>
            <div class="response_thumb">
                <img class="response_image" src="/img/connect/wb/add-new-key-modal.png" width="567" height="400" alt="">
            </div>
        </div>
    </template>
</template>

<script>
export default {
    name: 'WildberriesApiKeyHelp',
    props: {
        isKeyExpired: {
            type: Boolean,
            default: false,
            required: false
        }
    }
}
</script>
