<template>
    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>1</span>
                <p>Перейдите в личный кабинет Ozon Seller <a href="https://seller.ozon.ru" target="_blank">по этой ссылке</a> (если Вы не авторизованы, авторизуйтесь).</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/1.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>2</span>
                <p>Перейдите на страницу настроек</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/2.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>3</span>
                <p>Перейдите на вкладку Seller API “API ключи”</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/3.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>4</span>
                <p>Нажмите на кнопку “Сгенерировать ключ”.</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/4_1.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>5</span>
                <p>Введите название ключа на ваше усмотрение. Нажмите кнопку «Сгенерировать».</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/5.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>6</span>
                <p>Скопируйте созданный ключ и внесите его в поле ниже на этой странице, чтобы подключить API  Ozon Seller к Sellerdata.</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/6.png" width="567" height="300" alt="image">
        </div>
    </div>

    <div class="response_block">
        <div class="response_block_inner">
            <article class="response_content">
                <span>7</span>
                <p>Чтобы подключить API  Ozon Seller к Sellerdata также необходимо скопировать  клиентский идентификатор (Client ID).</p>
            </article>
        </div>
        <div class="response_thumb">
            <img class="response_image" src="/img/connect/ozon/7.png" width="567" height="300" alt="">
        </div>
    </div>

    <template v-if="isKeyExpired">
        <div class="response_block">
            <div class="response_block_inner">
                <article class="response_content">
                    <span>8</span>
                    <p>В&nbsp;разделе Настройки&nbsp;&mdash; Общие выберите магазин, для которого необходимо заменить ключ и&nbsp;нажмите кнопку &laquo;Обновить ключ&raquo;</p>
                </article>
            </div>
            <div class="response_thumb">
                <img class="response_image" src="/img/connect/ozon/ozon-change-key.png" width="567" height="400" alt="">
            </div>
        </div>

        <div class="response_block">
            <div class="response_block_inner">
                <article class="response_content">
                    <span>9</span>
                    <p>Внесите новый ключ в появившемся окне и сохраните изменения</p>
                </article>
            </div>
            <div class="response_thumb">
                <img class="response_image" src="/img/connect/ozon/ozon-add-new-key-modal.png" width="567" height="400" alt="image">
            </div>
        </div>
    </template>

</template>

<script>
export default {
    name: 'WildberriesApiKeyHelp',
    props: {
        isKeyExpired: {
            type: Boolean,
            default: false,
            required: false
        }
    }
}
</script>
